body {
    margin: 0;
}

h1 {
    color: #ff7b00;
}

h2 {
    color: #FF8B22;
}

h3 {
    color: #FF8B22;
}